<template>
  <footer class="bg-white text-gray-600">
    <div class="container mx-auto flex items-center justify-between sm:flex-row flex-col px-4">
      <div>
        <img alt="logo.png" src="img/logo.png" class="h-20" />
      </div>
      <div>
        <p class="text-sm text-center text-gray-500">
          Copyright &copy; {{ currentYear }} Embediq —
          <router-link to="/privacyverklaring" class="text-gray-600 hover:text-gray-800 ml-1 underline"
            rel="noopener noreferrer">
            Alle rechten voorbehouden
          </router-link>
        </p>
      </div>
      <div
        class="sm:mt-0 mt-4 bg-card hover:bg-primary rounded-full px-3 py-2 text-primary hover:text-white cursor-pointer invisible sm:visible">
        <a href="https://www.linkedin.com/company/embediq" aria-label="LinkedIn" target="_blank"><i
            class="fa-brands fa-linkedin-in w-15 h-15"></i></a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};
</script>