import { createApp } from 'vue'
// import mitt from 'mitt'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/styles.css'
import { install } from "vue3-recaptcha-v2";
import VueGtag from "vue-gtag-next";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)
dom.watch();
// const emitter = mitt()

createApp(App)
  .use(router)
  .use(VueGtag, {property: {id: "G-W48RNB4PM2"}})
  .use(install, {sitekey: "6LcU47QpAAAAAG9Q0t0c2vitQBS6IXfoC-Fo4fnA", cnDomains: false, })
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount('#app');

  // App.config.globalProperties.emitter = emitter
