<template>
    <section class="container grid grid-cols-1 md:grid-cols-2 items-center justify-center xl:w-8/12 mx-auto">
        <div class="p-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                <g id="freepik--background-complete--inject-63" class="w-full rounded-lg shadow-lg fill-primary">
                    <path
                        d="M238.4,445.05H45.3a5.71,5.71,0,0,1-5.71-5.71V60.66A5.71,5.71,0,0,1,45.3,55H238.4a5.71,5.71,0,0,1,5.71,5.71V439.34A5.71,5.71,0,0,1,238.4,445.05ZM45.3,55.2a5.47,5.47,0,0,0-5.46,5.46V439.34a5.47,5.47,0,0,0,5.46,5.46H238.4a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
                        style="fill:#ebebeb"></path>
                    <path
                        d="M454.7,445.05H261.6a5.71,5.71,0,0,1-5.71-5.71V60.66A5.71,5.71,0,0,1,261.6,55H454.7a5.71,5.71,0,0,1,5.71,5.71V439.34A5.71,5.71,0,0,1,454.7,445.05ZM261.6,55.2a5.47,5.47,0,0,0-5.46,5.46V439.34a5.47,5.47,0,0,0,5.46,5.46H454.7a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
                        style="fill:#ebebeb"></path>
                    <polygon
                        points="113.05 168.25 114.67 171.52 118.29 172.05 115.67 174.6 116.29 178.2 113.05 176.5 109.82 178.2 110.44 174.6 107.82 172.05 111.44 171.52 113.05 168.25"
                        style="fill:#ebebeb"></polygon>
                    <polygon
                        points="436.7 322.48 438.32 325.76 441.94 326.29 439.32 328.83 439.94 332.44 436.7 330.74 433.47 332.44 434.09 328.83 431.47 326.29 435.09 325.76 436.7 322.48"
                        style="fill:#f5f5f5"></polygon>
                    <polygon
                        points="372.6 417.57 374.22 420.84 377.83 421.37 375.21 423.92 375.83 427.52 372.6 425.82 369.37 427.52 369.98 423.92 367.37 421.37 370.98 420.84 372.6 417.57"
                        style="fill:#f5f5f5"></polygon>
                    <path
                        d="M225.94,110.15l.6,1.22,1.35.2a.4.4,0,0,1,.22.69l-1,.95.23,1.34a.41.41,0,0,1-.59.43l-1.2-.64-1.2.64a.41.41,0,0,1-.59-.43l.22-1.34-1-.95a.4.4,0,0,1,.23-.69l1.34-.2.6-1.22A.41.41,0,0,1,225.94,110.15Z"
                        style="fill:#ebebeb"></path>
                    <path
                        d="M60.78,402l.6,1.21,1.34.2a.41.41,0,0,1,.23.7l-1,.94.23,1.34a.41.41,0,0,1-.59.43l-1.2-.63-1.2.63a.41.41,0,0,1-.59-.43l.23-1.34-1-.94a.41.41,0,0,1,.22-.7l1.34-.2L60,402A.41.41,0,0,1,60.78,402Z"
                        style="fill:#f5f5f5"></path>
                    <path
                        d="M87.75,97.3l.6,1.22,1.35.19a.41.41,0,0,1,.22.7l-1,1,.23,1.33a.4.4,0,0,1-.59.43l-1.2-.63-1.2.63a.4.4,0,0,1-.59-.43l.22-1.33-1-1a.41.41,0,0,1,.23-.7l1.34-.19L87,97.3A.41.41,0,0,1,87.75,97.3Z"
                        style="fill:#ebebeb"></path>
                    <path d="M86.13,338.32A1.33,1.33,0,1,1,84.8,337,1.33,1.33,0,0,1,86.13,338.32Z" style="fill:#ebebeb">
                    </path>
                    <path d="M275.67,171.89a1.33,1.33,0,1,1-1.33-1.32A1.32,1.32,0,0,1,275.67,171.89Z"
                        style="fill:#ebebeb"></path>
                    <path d="M206.71,98.4a1.33,1.33,0,1,1-1.33-1.33A1.34,1.34,0,0,1,206.71,98.4Z" style="fill:#f5f5f5">
                    </path>
                    <circle cx="207.14" cy="415.38" r="7.35" transform="translate(-89.89 58.7) rotate(-13.28)"
                        style="fill:#f0f0f0"></circle>
                    <path
                        d="M204.6,411.09a7.09,7.09,0,0,0-4.19,1.36,7.34,7.34,0,0,0,10.5,9.23s0,0,0,0a7.17,7.17,0,0,0-6.31-10.58Z"
                        style="fill:#e6e6e6"></path>
                </g>
                <g id="freepik--404--inject-63">
                    <path
                        d="M147.68,287.64H86.83V260.17l60.85-72.34H176.8v73.9h15.09v25.91H176.8v22.48H147.68Zm0-25.91V223.89l-32.16,37.84Z"
                        style="fill:#4062BB"></path>
                    <path
                        d="M202.3,249.51q0-34.29,12.34-48t37.61-13.7q12.13,0,19.93,3a36.79,36.79,0,0,1,12.71,7.79,41.59,41.59,0,0,1,7.75,10.09,52.38,52.38,0,0,1,4.55,12.34,115.36,115.36,0,0,1,3.36,28q0,32.72-11.07,47.89t-38.13,15.18q-15.18,0-24.53-4.84a39.76,39.76,0,0,1-15.33-14.19q-4.35-6.64-6.77-18.17A124.33,124.33,0,0,1,202.3,249.51Zm33.14.08q0,23,4.05,31.37t11.77,8.41a12.34,12.34,0,0,0,8.82-3.57q3.74-3.57,5.5-11.28t1.76-24q0-23.94-4.06-32.19t-12.18-8.24q-8.28,0-12,8.41T235.44,249.59Z"
                        style="fill:#4062BB"></path>
                    <path
                        d="M371.74,287.64H310.89V260.17l60.85-72.34h29.12v73.9H416v25.91H400.86v22.48H371.74Zm0-25.91V223.89l-32.15,37.84Z"
                        style="fill:#4062BB"></path>
                </g>
                <g id="freepik--Planets--inject-63">
                    <g style="opacity:0.30000000000000004">
                        <path d="M201,145.62a1.87,1.87,0,1,1-1.86-1.87A1.86,1.86,0,0,1,201,145.62Z"
                            style="fill:#407BFF"></path>
                        <circle cx="72.97" cy="216.13" r="1.32" style="fill:#407BFF"></circle>
                        <circle cx="291.05" cy="408.33" r="1.89" style="fill:#407BFF"></circle>
                        <circle cx="336.5" cy="332" r="1.32" style="fill:#407BFF"></circle>
                        <path d="M424.17,95.62a1.32,1.32,0,1,1-1.32-1.32A1.32,1.32,0,0,1,424.17,95.62Z"
                            style="fill:#407BFF"></path>
                        <path d="M172.75,69a1.32,1.32,0,1,1-1.32-1.32A1.33,1.33,0,0,1,172.75,69Z" style="fill:#407BFF">
                        </path>
                        <circle cx="277.7" cy="136.94" r="1.32" style="fill:#407BFF"></circle>
                    </g>
                    <circle cx="141.23" cy="116.36" r="21.91" style="fill:#407BFF"></circle>
                    <circle cx="141.23" cy="116.36" r="21.91" style="fill:#fff;opacity:0.7000000000000001"></circle>
                    <path
                        d="M133.68,99.83A21.84,21.84,0,0,0,125,101.6a21.92,21.92,0,0,0,24.87,34.89h0a21.92,21.92,0,0,0-16.23-36.65Z"
                        style="fill:#407BFF;opacity:0.2"></path>
                    <path d="M131.5,105.62a2,2,0,1,1-2-2A2,2,0,0,1,131.5,105.62Z" style="fill:#407BFF;opacity:0.2">
                    </path>
                    <path d="M155.06,103.62a2,2,0,1,1-2-2A2,2,0,0,1,155.06,103.62Z" style="fill:#407BFF;opacity:0.2">
                    </path>
                    <path d="M151.06,117.9a3.28,3.28,0,1,1-3.28-3.28A3.28,3.28,0,0,1,151.06,117.9Z"
                        style="fill:#407BFF;opacity:0.2"></path>
                    <path d="M140.64,127.25a4.38,4.38,0,1,1-4.38-4.38A4.38,4.38,0,0,1,140.64,127.25Z"
                        style="fill:#407BFF;opacity:0.2"></path>
                    <circle cx="382.2" cy="376.25" r="19.23" transform="translate(-71.8 661.78) rotate(-76.72)"
                        style="fill:#407BFF"></circle>
                    <circle cx="382.2" cy="376.25" r="19.23" transform="translate(-71.8 661.78) rotate(-76.72)"
                        style="fill:#fff;opacity:0.30000000000000004"></circle>
                    <path
                        d="M394.33,361.34a19.22,19.22,0,0,0-17.67,33.32,19,19,0,0,0,5.53.82,19.23,19.23,0,0,0,12.14-34.14Z"
                        style="fill:#407BFF;opacity:0.4"></path>
                    <path
                        d="M363.83,382c-20.53,9.66-5.22,17.11,23.71,6.71,26.79-9.63,37-21.77,13-18C401.83,375.76,368.28,388.83,363.83,382Z"
                        style="fill:#4062BB"></path>
                </g>
                <g id="freepik--Astronaut--inject-63">
                    <path d="M394.1,187.83C367.21,206,332.4,230,322.79,287.64h-2.05c9.35-57,42.89-81.57,69.79-99.81Z"
                        style="opacity:0.2"></path>
                    <path
                        d="M255,368.27c-17,0-33.81-7.67-42-20.19-5.05-7.74-10.92-23.95,6.56-45.58l1.55,1.26c-12.36,15.3-14.64,30.65-6.43,43.23,10,15.3,33.59,23,53.73,17.52,20.63-5.61,33.15-23.55,34.36-49.22,4.13-87.81,50.78-114.86,84.84-134.61,21.17-12.27,36.46-21.13,33.1-39.84-.47-2.59-1.5-4.38-3.17-5.48-4.35-2.87-12.85-.88-22.69,1.41-19.31,4.5-45.75,10.66-61.5-16.13l1.73-1c15,25.53,39.57,19.8,59.32,15.2,10.29-2.39,19.17-4.46,24.24-1.13,2.15,1.41,3.47,3.64,4,6.8,3.61,20.08-13,29.72-34.05,41.92-33.67,19.52-79.77,46.25-83.85,133-1.26,26.6-14.32,45.21-35.84,51.06A52.88,52.88,0,0,1,255,368.27Z"
                        style="fill:#407BFF"></path>
                    <path
                        d="M255,368.27c-17,0-33.81-7.67-42-20.19-5.05-7.74-10.92-23.95,6.56-45.58l1.55,1.26c-12.36,15.3-14.64,30.65-6.43,43.23,10,15.3,33.59,23,53.73,17.52,20.63-5.61,33.15-23.55,34.36-49.22,4.13-87.81,50.78-114.86,84.84-134.61,21.17-12.27,36.46-21.13,33.1-39.84-.47-2.59-1.5-4.38-3.17-5.48-4.35-2.87-12.85-.88-22.69,1.41-19.31,4.5-45.75,10.66-61.5-16.13l1.73-1c15,25.53,39.57,19.8,59.32,15.2,10.29-2.39,19.17-4.46,24.24-1.13,2.15,1.41,3.47,3.64,4,6.8,3.61,20.08-13,29.72-34.05,41.92-33.67,19.52-79.77,46.25-83.85,133-1.26,26.6-14.32,45.21-35.84,51.06A52.88,52.88,0,0,1,255,368.27Z"
                        style="fill:#fff;opacity:0.2"></path>
                    <path
                        d="M312.76,97a46.05,46.05,0,0,1,13.58,2.13s11,18.77,12.3,23.07c-.46,4.24-7.61,11.19-7.61,11.19Z"
                        style="fill:#4062BB"></path>
                    <path
                        d="M312.76,97a46.05,46.05,0,0,1,13.58,2.13s11,18.77,12.3,23.07c-.46,4.24-7.61,11.19-7.61,11.19Z"
                        style="fill:#4062BB;opacity:0.30000000000000004"></path>
                    <path
                        d="M345.34,188.13a141.41,141.41,0,0,1-11.56-16.38q-1.26-2.17-2.39-4.42c-.43-.85-.84-1.7-1.24-2.56a10.76,10.76,0,0,1-1.21-2.69c-1.2-12.67,3.14-22-1-32.17l-16.48,6.44s1.4,18.12,4.6,29c2,6.73,6.48,12.55,10.81,17.94,1.35,1.68,2.65,3.41,4,5.1s2.71,3.06,4,4.65c1.95,2.41,2.59,4.72,1.12,7.56l-.25.45c-.42.74,1.54,1.58,2.78,0,2-2.58,1.72-2.42,3.46-4.62,1.06-1.33,2.27-2.78,3.32-4A3.37,3.37,0,0,0,345.34,188.13Z"
                        style="fill:#407BFF"></path>
                    <path
                        d="M345.34,188.13a141.41,141.41,0,0,1-11.56-16.38q-1.26-2.17-2.39-4.42c-.43-.85-.84-1.7-1.24-2.56a10.76,10.76,0,0,1-1.21-2.69c-1.2-12.67,3.14-22-1-32.17l-16.48,6.44s1.4,18.12,4.6,29c2,6.73,6.48,12.55,10.81,17.94,1.35,1.68,2.65,3.41,4,5.1s2.71,3.06,4,4.65c1.95,2.41,2.59,4.72,1.12,7.56l-.25.45c-.42.74,1.54,1.58,2.78,0,2-2.58,1.72-2.42,3.46-4.62,1.06-1.33,2.27-2.78,3.32-4A3.37,3.37,0,0,0,345.34,188.13Z"
                        style="fill:#fff;opacity:0.7000000000000001"></path>
                    <path
                        d="M341.31,182.92a54.69,54.69,0,0,1-8.66,7.52c.43.48.85,1,1.28,1.46a43.92,43.92,0,0,0,8.5-7.51Z"
                        style="fill:#407BFF;opacity:0.30000000000000004"></path>
                    <path
                        d="M345.34,188.13l-.12-.14a5.18,5.18,0,0,0-1.27,3.17,5,5,0,0,0,.38,2.35l.95-1.13A3.37,3.37,0,0,0,345.34,188.13Z"
                        style="fill:#407BFF;opacity:0.30000000000000004"></path>
                    <path
                        d="M308.84,109a35.38,35.38,0,0,1-6.37,7.19,23.27,23.27,0,0,1-4.42,3,19,19,0,0,1-2.58,1.09l-.68.22-.22.06-.47.13a5.93,5.93,0,0,1-.88.14,7.55,7.55,0,0,1-2.51-.23,12.24,12.24,0,0,1-2.94-1.27,25,25,0,0,1-2.15-1.41,40.31,40.31,0,0,1-3.58-3,53.16,53.16,0,0,1-6-6.74,2.51,2.51,0,0,1,3.35-3.62l.08,0c2.36,1.5,4.74,3.08,7.06,4.49,1.18.69,2.32,1.39,3.45,1.93a15.29,15.29,0,0,0,1.59.72,3.12,3.12,0,0,0,1.07.26c.06,0,0-.07-.37-.06a2.93,2.93,0,0,0-.35,0l-.22.05,0,0,.33-.17a13.53,13.53,0,0,0,1.29-.79,18.4,18.4,0,0,0,2.5-2.12,63.62,63.62,0,0,0,4.9-5.79l0,0a5,5,0,0,1,8,5.93Z"
                        style="fill:#407BFF"></path>
                    <path
                        d="M308.84,109a35.38,35.38,0,0,1-6.37,7.19,23.27,23.27,0,0,1-4.42,3,19,19,0,0,1-2.58,1.09l-.68.22-.22.06-.47.13a5.93,5.93,0,0,1-.88.14,7.55,7.55,0,0,1-2.51-.23,12.24,12.24,0,0,1-2.94-1.27,25,25,0,0,1-2.15-1.41,40.31,40.31,0,0,1-3.58-3,53.16,53.16,0,0,1-6-6.74,2.51,2.51,0,0,1,3.35-3.62l.08,0c2.36,1.5,4.74,3.08,7.06,4.49,1.18.69,2.32,1.39,3.45,1.93a15.29,15.29,0,0,0,1.59.72,3.12,3.12,0,0,0,1.07.26c.06,0,0-.07-.37-.06a2.93,2.93,0,0,0-.35,0l-.22.05,0,0,.33-.17a13.53,13.53,0,0,0,1.29-.79,18.4,18.4,0,0,0,2.5-2.12,63.62,63.62,0,0,0,4.9-5.79l0,0a5,5,0,0,1,8,5.93Z"
                        style="fill:#fff;opacity:0.7000000000000001"></path>
                    <path
                        d="M272.29,102.42l1.17,2s.89,2.62,2.68,3.1l4.86-1.57-.25-.41h0c-.62-.94-.55-2.77-.34-4.29s-.57-1.57-1.15-1.19a3.82,3.82,0,0,0-.84,1.65,7.77,7.77,0,0,0-.79-.93l-1.48-1.48a1.72,1.72,0,0,0-2.34-.06l-1.2,1.07A1.71,1.71,0,0,0,272.29,102.42Z"
                        style="fill:#407BFF"></path>
                    <path
                        d="M272.29,102.42l1.17,2s.89,2.62,2.68,3.1l4.86-1.57-.25-.41h0c-.62-.94-.55-2.77-.34-4.29s-.57-1.57-1.15-1.19a3.82,3.82,0,0,0-.84,1.65,7.77,7.77,0,0,0-.79-.93l-1.48-1.48a1.72,1.72,0,0,0-2.34-.06l-1.2,1.07A1.71,1.71,0,0,0,272.29,102.42Z"
                        style="fill:#fff;opacity:0.7000000000000001"></path>
                    <path
                        d="M317.67,95.22a59.64,59.64,0,0,0-15.34,6.47,4.32,4.32,0,0,0-1.94,4.53c1.93,9.44,6.32,22.08,11.06,30.13l22.11-9.15c.15-3.9-5.22-16.52-10.69-28.72C321.89,96.29,320,94.66,317.67,95.22Z"
                        style="fill:#407BFF"></path>
                    <path
                        d="M317.67,95.22a59.64,59.64,0,0,0-15.34,6.47,4.32,4.32,0,0,0-1.94,4.53c1.93,9.44,6.32,22.08,11.06,30.13l22.11-9.15c.15-3.9-5.22-16.52-10.69-28.72C321.89,96.29,320,94.66,317.67,95.22Z"
                        style="fill:#fff;opacity:0.8"></path>
                    <path d="M326.3,106.21l-4.39-1.47c1,2.57,4.53,5.82,7,7.73C328.11,110.47,327.22,108.37,326.3,106.21Z"
                        style="fill:#407BFF;opacity:0.30000000000000004"></path>
                    <path
                        d="M316.22,85.32c-1.83-3.48-5.78-5.23-10.52-4.84-4,.34-7.54,4.42-7.12,6.62S302.36,90.24,303,91l-2.77,2a3,3,0,0,0-.6,4.29c1.17,1.48,2.71,3,3.6,4.12,7.66-.2,13.33-3.12,15.38-5.93C317.84,91.92,318,88.78,316.22,85.32Z"
                        style="fill:#407BFF"></path>
                    <path
                        d="M316.22,85.32c-1.83-3.48-5.78-5.23-10.52-4.84-4,.34-7.54,4.42-7.12,6.62S302.36,90.24,303,91l-2.77,2a3,3,0,0,0-.6,4.29c1.17,1.48,2.71,3,3.6,4.12,7.66-.2,13.33-3.12,15.38-5.93C317.84,91.92,318,88.78,316.22,85.32Z"
                        style="fill:#fff;opacity:0.8"></path>
                    <path d="M312.46,87.48a7.57,7.57,0,1,1-9.81-4.3A7.58,7.58,0,0,1,312.46,87.48Z" style="fill:#263238">
                    </path>
                    <path
                        d="M377.39,177.6c-.11-3.29-.26-3-.35-5.77-.06-1.7-.07-3.59-.08-5.22a3.36,3.36,0,0,0-2.7-3.28c-1.32-.27-2.65-.52-4-.8-1.73-.37-3.44-.77-5.13-1.26-1.32-.38-2.62-.8-3.91-1.27s-2.74-1-4.08-1.62c-1.58-.67-3.14-1.39-4.68-2.14-1.73-.82-3.44-1.68-5.15-2.55-6.58-10.89-6.72-18.07-13.78-26.49l-15.16,6.86s11.14,19.76,18.72,28.14c4.37,4.82,11.22,7,17.33,8.58,4.41,1.13,8.88,2,13.35,2.83,1.74.32,3.63.44,5.13,1.48a5.74,5.74,0,0,1,2.14,3.45q.1.42.18.84C375.41,180.22,377.46,179.58,377.39,177.6Z"
                        style="fill:#407BFF"></path>
                    <path
                        d="M377.39,177.6c-.11-3.29-.26-3-.35-5.77-.06-1.7-.07-3.59-.08-5.22a3.36,3.36,0,0,0-2.7-3.28c-1.32-.27-2.65-.52-4-.8-1.73-.37-3.44-.77-5.13-1.26-1.32-.38-2.62-.8-3.91-1.27s-2.74-1-4.08-1.62c-1.58-.67-3.14-1.39-4.68-2.14-1.73-.82-3.44-1.68-5.15-2.55-6.58-10.89-6.72-18.07-13.78-26.49l-15.16,6.86s11.14,19.76,18.72,28.14c4.37,4.82,11.22,7,17.33,8.58,4.41,1.13,8.88,2,13.35,2.83,1.74.32,3.63.44,5.13,1.48a5.74,5.74,0,0,1,2.14,3.45q.1.42.18.84C375.41,180.22,377.46,179.58,377.39,177.6Z"
                        style="fill:#fff;opacity:0.8"></path>
                    <path
                        d="M369.7,162.4c-.6-.13-1.2-.26-1.81-.41.05,3.46-1.57,9.42-2.16,11.23l1.9.36A38.11,38.11,0,0,0,369.7,162.4Z"
                        style="fill:#407BFF;opacity:0.30000000000000004"></path>
                    <path
                        d="M377,166.61a3.36,3.36,0,0,0-2.69-3.28l-1-.19a4.58,4.58,0,0,0,1.63,2.9,5.09,5.09,0,0,0,2,1.14C377,167,377,166.8,377,166.61Z"
                        style="fill:#407BFF;opacity:0.30000000000000004"></path>
                    <path
                        d="M311.05,87.54c.4,1.52-1.3,3.11-2.65,1.8a30.83,30.83,0,0,0-4.12-3.69c-1.39-.87.46-2.39,2.65-1.8A5.94,5.94,0,0,1,311.05,87.54Z"
                        style="fill:#fff"></path>
                    <path
                        d="M311.16,135.86c-.7.26.58,1.46.58,1.46s14-4.79,22.5-9.72a1.88,1.88,0,0,0-.68-1.58A216,216,0,0,1,311.16,135.86Z"
                        style="fill:#407BFF"></path>
                    <path
                        d="M311.16,135.86c-.7.26.58,1.46.58,1.46s14-4.79,22.5-9.72a1.88,1.88,0,0,0-.68-1.58A216,216,0,0,1,311.16,135.86Z"
                        style="fill:#fff;opacity:0.5"></path>
                    <path
                        d="M321.46,94.56c2.76,1.4,5.35,2.87,8,4.5,1.29.82,2.57,1.65,3.84,2.55s2.53,1.82,3.8,2.86l.47.39.59.54a12.74,12.74,0,0,1,1,1c.32.35.59.69.85,1s.54.68.77,1a43.8,43.8,0,0,1,2.58,4,59.05,59.05,0,0,1,4,8.35,2.52,2.52,0,0,1-4.19,2.62l-.05-.06c-2-2.13-3.93-4.37-5.87-6.46s-3.91-4.21-5.54-5.14c-2.27-1.41-4.8-2.82-7.31-4.2l-7.56-4.2h0a5,5,0,0,1,4.68-8.84Z"
                        style="fill:#407BFF"></path>
                    <path
                        d="M321.46,94.56c2.76,1.4,5.35,2.87,8,4.5,1.29.82,2.57,1.65,3.84,2.55s2.53,1.82,3.8,2.86l.47.39.59.54a12.74,12.74,0,0,1,1,1c.32.35.59.69.85,1s.54.68.77,1a43.8,43.8,0,0,1,2.58,4,59.05,59.05,0,0,1,4,8.35,2.52,2.52,0,0,1-4.19,2.62l-.05-.06c-2-2.13-3.93-4.37-5.87-6.46s-3.91-4.21-5.54-5.14c-2.27-1.41-4.8-2.82-7.31-4.2l-7.56-4.2h0a5,5,0,0,1,4.68-8.84Z"
                        style="fill:#fff;opacity:0.8"></path>
                    <path
                        d="M349.73,125.74l-.85-2.13s-.47-2.72-2.16-3.48l-5,.79.17.44h0c.46,1,.11,2.83-.34,4.29s.31,1.65.95,1.36c.36-.16.71-.81,1.09-1.5a8.46,8.46,0,0,0,.63,1l1.23,1.69a1.72,1.72,0,0,0,2.3.44l1.36-.87A1.7,1.7,0,0,0,349.73,125.74Z"
                        style="fill:#407BFF"></path>
                    <path
                        d="M349.73,125.74l-.85-2.13s-.47-2.72-2.16-3.48l-5,.79.17.44h0c.46,1,.11,2.83-.34,4.29s.31,1.65.95,1.36c.36-.16.71-.81,1.09-1.5a8.46,8.46,0,0,0,.63,1l1.23,1.69a1.72,1.72,0,0,0,2.3.44l1.36-.87A1.7,1.7,0,0,0,349.73,125.74Z"
                        style="fill:#fff;opacity:0.8"></path>
                    <path
                        d="M317.24,106.06l-1.22.1-7.49,18.08a4,4,0,0,0,1.22-.1s9.76-3.64,12.71-5C320.48,115.05,317.24,106.06,317.24,106.06Z"
                        style="fill:#407BFF;opacity:0.30000000000000004"></path>
                    <path
                        d="M303.57,110.8a43.41,43.41,0,0,0,5,13.44c3.66-1.26,9.76-3.64,12.72-5A135.36,135.36,0,0,1,316,106.16C312.87,106.37,306,109,303.57,110.8Z"
                        style="fill:#fff"></path>
                    <path d="M311,114.71a2.58,2.58,0,1,1-1.73-3.21A2.58,2.58,0,0,1,311,114.71Z"
                        style="fill:#407BFF;opacity:0.30000000000000004"></path>
                    <path d="M312.91,111.27a.85.85,0,1,1-.56-1A.84.84,0,0,1,312.91,111.27Z"
                        style="fill:#407BFF;opacity:0.6000000000000001"></path>
                    <path d="M315.15,110.4a.85.85,0,1,1-1.62-.49.84.84,0,0,1,1.05-.56A.85.85,0,0,1,315.15,110.4Z"
                        style="fill:#407BFF;opacity:0.6000000000000001"></path>
                    <polygon points="318.29 118.19 309.04 121.84 308.49 120.02 317.73 116.37 318.29 118.19"
                        style="fill:#407BFF;opacity:0.5"></polygon>
                </g>
                <g id="freepik--Rocket--inject-63">
                    <path
                        d="M267.26,257.17a94,94,0,0,1-1.68,17.35q-1.77,7.71-5.5,11.28a12.3,12.3,0,0,1-8.81,3.57q-7.71,0-11.77-8.41a23.79,23.79,0,0,1-1.21-3.11,144.31,144.31,0,0,0-15.92,16l-5.31,6.26a38.62,38.62,0,0,0,9.77,7.19q9.34,4.83,24.52,4.84c1.78,0,3.5-.05,5.17-.15a143.39,143.39,0,0,0,15.1-29l14.85-38.72Z"
                        style="opacity:0.2"></path>
                    <path d="M133.39,310l17.5,17.5,49-46.17C183,274.88,150.16,293.19,133.39,310Z" style="fill:#263238">
                    </path>
                    <path d="M194.42,371c-5.59-5.6-17.5-17.5-17.5-17.5l46.17-49C229.5,321.35,211.19,354.22,194.42,371Z"
                        style="fill:#263238"></path>
                    <path
                        d="M261.4,260.7l19.09-36.81L243.68,243a144.22,144.22,0,0,0-32.44,23l-62.75,59.07,30.83,30.83,59.07-62.75A144.22,144.22,0,0,0,261.4,260.7Z"
                        style="fill:#407BFF"></path>
                    <path
                        d="M261.4,260.7l19.09-36.81L243.68,243a144.22,144.22,0,0,0-32.44,23l-62.75,59.07,30.83,30.83,59.07-62.75A144.22,144.22,0,0,0,261.4,260.7Z"
                        style="fill:#fff;opacity:0.6000000000000001"></path>
                    <circle cx="222.2" cy="282.18" r="12.9" style="fill:#fff"></circle>
                    <circle cx="222.2" cy="282.18" r="8.29" style="fill:#4062BB"></circle>
                    <polygon points="189.75 344.82 159.56 314.63 184.28 291.37 213.01 320.11 189.75 344.82"
                        style="fill:#4062BB;opacity:0.30000000000000004"></polygon>
                    <path
                        d="M140.22,337.62c-22.6,1.83-30.09,16.3-32.65,35.53-1.3,9.81-1.88,19.74-10.11,25.48a2.77,2.77,0,0,0,1.63,5.06c30.34-.95,44.49-15.8,46.27-22a43.06,43.06,0,0,1-2.49,9.47,2.76,2.76,0,0,0,4,3.39c8.51-5.33,19.19-15.15,19.9-31.08C160.51,354.6,140.22,337.62,140.22,337.62Z"
                        style="fill:#407BFF"></path>
                    <path
                        d="M140.22,337.62c-22.6,1.83-30.09,16.3-32.65,35.53-1.3,9.81-1.88,19.74-10.11,25.48a2.77,2.77,0,0,0,1.63,5.06c30.34-.95,44.49-15.8,46.27-22a43.06,43.06,0,0,1-2.49,9.47,2.76,2.76,0,0,0,4,3.39c8.51-5.33,19.19-15.15,19.9-31.08C160.51,354.6,140.22,337.62,140.22,337.62Z"
                        style="fill:#fff;opacity:0.2"></path>
                    <polygon points="170.28 370.3 134.08 334.1 153.36 329.93 174.45 351.02 170.28 370.3"
                        style="fill:#4062BB"></polygon>
                </g>
            </svg>
        </div>
        <div class="w-full p-4 md:p-8">
            <h1 class="text-xl md:text-4xl font-bold mb-4">OEPS! Er is iets fout gegaan</h1>
            <p class="text-md md:text-xl mb-8">We konden de pagina die u zocht niet vinden. Dit komt misschien door:</p>
            <ul class="space-y-2 list-disc list-outside mb-12 ml-5">
                <li>
                    Er zit een fout in de URL die in uw webbrowser is ingevoerd. Controleer de URL en probeer het opnieuw.
                </li>
                <li>
                    De pagina die u zoekt is verplaatst of verwijderd.
                </li>
            </ul>
            <div class="flex sm:block">
                <router-link to="/" href="/"
                class="px-6 py-3 mx-auto bg-primary text-white rounded-full hover:bg-secondary transition duration-300 ease-in-out">Terug
                naar Home</router-link>
            </div>
        </div>
        
    </section>
</template>