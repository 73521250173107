// router.js
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Contact from '../views/ContactView.vue'
import Privacyverklaring from '../views/PrivacyView.vue'
import PageNotFound from '@/components/PageNotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/privacyverklaring',
    name: 'Privacyverklaring',
    component: Privacyverklaring
  },
  {
    path: '/:pathMatch',
    name: 'PageNotFound',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router