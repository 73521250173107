<template>
        <section class="bg-white" id="overons">
        <div class="container mx-auto px-4 pt-40 pb-20">
            <h1>Embediq, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze
            privacyverklaring.</h1><br><br>

        <h3>Google reCAPTCHA</h3>
        Wij gebruiken de reCAPTCHA service van Google Inc. (Google) om gegevens te beschermen die verstuurd worden via
        deze website. Die functie controleert of een bericht wel door een persoon wordt verstuurd, om zo te voorkomen
        dat ‘spam bots’ bijvoorbeeld automatisch berichten sturen of commentaren achterlaten. Hierbij worden het IP
        adres van een bezoeker en mogelijk ook andere data verstuurd om Google reCAPTCHA te laten werken. De inhoud van
        een bericht wordt hiervoor verstuurd naar en verwerkt door Google. Het IP adres wordt hierbij afgekort en
        daardoor anoniem gemaakt in landen die deel uitmaken van de EU en staten die deelnemen aan het verdrag voor het
        Europese economische gebied. In uitzonderingsgevallen wordt een IP adres volledig doorgegeven aan een Google
        server in de Verenigde Staten en daar afgekort. Namens de bezitter van deze website gebruikt Google deze
        gegevens om je gebruik van deze dienst te evalueren. Het IP adres dat reCAPTCHA doorgeeft, wordt niet
        samengevoegd met andere data van Google.<br><br>

        Op het verzamelen van deze gegevens zijn Google’s regels voor gegevensbescherming van toepassing. Meer
        informatie over over Google’s privacybeleid vind je op deze pagina:
        https://www.google.com/intl/en/policies/privacy/<br><br>

        Door reCAPTCHA te gebruiken gaat u ermee akkoord dat Google gegevens over u verwerkt op de manier en voor het
        doel zoals hierboven beschreven.<br><br>

        <h3>Google Analytics</h3>
        Via onze website wordt een cookie geplaatst van het Amerikaanse bedrijf Google, als deel van de
        “Analytics”-dienst. Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe bezoekers de
        website gebruiken. Google kan deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt
        verplicht, of voor zover derden de informatie namens Google verwerken. Wij hebben hier geen invloed op. Wij
        hebben Google niet toegestaan de verkregen analytics informatie te gebruiken voor andere Google
        diensten.<br><br>

        De informatie die Google verzamelt wordt zo veel mogelijk geanonimiseerd. Uw IP-adres wordt nadrukkelijk niet
        meegegeven (geanonimiseerd). De informatie wordt overgebracht naar en door Google opgeslagen op servers in de
        Verenigde Staten. Google stelt zich te houden aan de Privacy Shield principles en is aangesloten bij het Privacy
        Shield-programma van het Amerikaanse Ministerie van Handel. Dit houdt in dat er sprake is van een passend
        beschermingsniveau voor de verwerking van eventuele persoonsgegevens.”<br><br>

        Meer informatie over het privacybeleid van Google.<br><br>

        <h3>Persoonsgegevens die wij verwerken</h3>
        Maikel verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons
        verstrekt. Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:<br><br>

        Voor- en achternaam
        E-mailadres
        Locatiegegevens
        Gegevens over uw activiteiten op onze website
        Gegevens over uw surfgedrag over verschillende websites heen (bijvoorbeeld omdat dit bedrijf onderdeel is van
        een advertentienetwerk)
        Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
        Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn
        dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker
        ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om
        zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als u er van
        overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem
        dan contact met ons op via het contact formulier dan verwijderen wij deze informatie.<br><br>

        Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken
        Maikel verwerkt uw persoonsgegevens voor de volgende doelen:<br><br>

        U te kunnen e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren
        Maikel analyseert uw gedrag op de website om daarmee de website te verbeteren en het aanbod van producten en
        diensten af te stemmen op uw
        voorkeuren.
        Maikel volgt uw surfgedrag over verschillende websites waarmee wij onze producten en diensten afstemmen op uw
        behoefte.
        Geautomatiseerde besluitvorming
        Maikel neemt op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen
        hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma’s of -systemen,
        zonder dat daar een mens (bijvoorbeeld een medewerker van Maikel) tussen zit.<br><br>

        <h3>Hoe lang we persoonsgegevens bewaren</h3>
        Maikel bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw
        gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van
        persoonsgegevens: 1 maand.<br><br>

        <h3>Delen van persoonsgegevens met derden</h3>
        Maikel deelt uw persoonsgegevens met verschillende derden als dit noodzakelijk is voor het uitvoeren van de
        overeenkomst en om te voldoen aan een eventuele wettelijke verplichting. Met bedrijven die u gegevens verwerken
        in onze opdracht, sluiten wij een verwerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en
        vertrouwelijkheid van uw gegevens. Maikel blijft verantwoordelijk voor deze verwerkingen. Daarnaast verstrekt
        Maikel uw persoonsgegevens aan andere derden. Dit doen wij alleen met uw nadrukkelijke toestemming. Google
        Analytics.<br><br>

        <h3>Cookies, of vergelijkbare technieken, die wij gebruiken</h3>
        Maikel gebruikt functionele, analytische en tracking cookies. Een cookie is een klein tekstbestand dat bij het
        eerste bezoek aan deze website wordt opgeslagen in de browser van uw computer, tablet of smartphone. Maikel
        gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt
        en dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website
        goed te laten werken en deze te kunnen optimaliseren. Daarnaast plaatsen we cookies die uw surfgedrag bijhouden
        zodat we op maat gemaakte content en advertenties kunnen aanbieden. Bij uw eerste bezoek aan onze website hebben
        wij u al geïnformeerd over deze cookies en toestemming gevraagd voor het plaatsen ervan. U kunt zich afmelden
        voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook
        alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen. Zie voor een
        toelichting: https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/<br><br>

        <h3>Gegevens inzien, aanpassen of verwijderen</h3>
        U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het
        recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de
        verwerking van uw persoonsgegevens door Maikel en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent
        dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand
        naar u of een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage, correctie,
        verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van uw toestemming of
        bezwaar op de verwerking van uw persoonsgegevens sturen via het contact formulier. Om er zeker van te zijn dat
        het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te
        sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort),
        paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. We reageren zo snel
        mogelijk, maar binnen vier weken, op uw verzoek. Maikel wil u er tevens op wijzen dat u de mogelijkheid heeft om
        een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende
        link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons<br><br>

        <h3>Hoe wij persoonsgegevens beveiligen</h3>
        Maikel neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies,
        onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft
        dat uw gegevens niet goed beveiligd zijn of er zijn aanwijzingen van misbruik, neem dan contact op met onze
        klantenservice of via het contact formulier. Maikel heeft de volgende maatregelen genomen om uw persoonsgegevens
        te beveiligen.<br><br>

        TLS (voorheen SSL) Wij versturen uw gegevens via een beveiligde internetverbinding. Dit kunt u zien aan de
        adresbalk ‘https’ en het hangslotje in de adresbalk.<br><br>
    </div>
    </section>
</template>
