<template>
    <section class="bg-white px-4 py-20 lg:py-40" id="home">
        <div class="container mx-auto rounded-2xl bg-card px-10 lg:px-16 py-20 grid lg:grid-cols-2 gap-8 shadow-lg">
            <div class="grid content-center">
                <div class="flex mb-8 justify-center sm:justify-normal">
                    <p
                        class="text-lg font-semibold rounded-r-2xl rounded-tl-2xl bg-primary py-2 px-4 shadow-xl text-white">
                        {{
                            begroeting
                        }}<span class="wave text-xl">👋🏻</span></p>
                </div>
                <h1 class="text-6xl font-bold mb-4 text-center sm:text-left font-comfortaa">Embediq</h1>
                <h2 class="text-xl font-bold mb-4 text-center sm:text-left">Wij houden ons bezig met
                    <span class="typed-text text-primary">{{ typeValue }}</span>
                    <span class="blinking-cursor text-primary">|</span>
                    <span class="cursor text-primary" :class="{ typing: typeStatus }">&nbsp;</span>
                </h2>
                <p class="text-lg mb-8 text-center sm:text-left">Uw partner voor professioneel webdesign.
                    Wij maken op maat gemaakte websites die indruk maken en resultaten opleveren.<br>
                    Met ons brengen wij uw online visie tot leven.</p>
            </div>
            <div class="w-full h-full">
                <img src="img/headerimage.jpg" alt="headerimage.png" class="w-full h-full object-cover rounded-2xl" />
            </div>
        </div>
    </section>
</template>

<script>
import eventBus from '../utils/EventBus.js';
export default {
    name: 'HomeView',
    data() {
        return {
            begroeting: '',
            typeValue: "",
            typeStatus: false,
            displayTextArray: ["Maatwerk websites.", "Onderhoud & Hosting.", "SEO."],
            typingSpeed: 100,
            erasingSpeed: 100,
            newTextDelay: 2000,
            displayTextArrayIndex: 0,
            charIndex: 0,
        };
    },
    mounted() {
        const myDate = new Date();
        const hrs = myDate.getHours();
        let begroetingValue = '';
        if (hrs < 12) {
            begroetingValue = 'Goedemorgen';
        } else if (hrs >= 12 && hrs < 18) {
            begroetingValue = 'Goedemiddag';
        } else if (hrs >= 18) {
            begroetingValue = 'Goedeavond';
        }

        this.begroeting = begroetingValue;
    },
    props: {}, created() {
        setTimeout(this.typeText, this.newTextDelay + 200);
    },
    methods: {
        triggerNavBarFunction() {
            eventBus.emit('trigger-navbar-function');
        },
        typeText() {
            if (this.charIndex <
                this.displayTextArray[this.displayTextArrayIndex].length) {
                if (!this.typeStatus) this.typeStatus = true;
                this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(this.charIndex); this.charIndex += 1;
                setTimeout(this.typeText, this.typingSpeed);
            } else {
                this.typeStatus = false; setTimeout(this.eraseText,
                    this.newTextDelay);
            }
        },
        eraseText() {
            if (this.charIndex > 0) {
                if (!this.typeStatus) this.typeStatus = true;
                this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(
                    0,
                    this.charIndex - 1
                );
                this.charIndex -= 1;
                setTimeout(this.eraseText, this.erasingSpeed);
            } else {
                this.typeStatus = false;
                this.displayTextArrayIndex += 1;
                if (this.displayTextArrayIndex >= this.displayTextArray.length)
                    this.displayTextArrayIndex = 0;
                setTimeout(this.typeText, this.typingSpeed + 1000);
            }
        },
    },
};
</script>

<style>
.wave {
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-name: wave-animation;
    display: inline-block;
    transform-origin: 70% 70%;
}

@keyframes wave-animation {
    0% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(14deg);
    }

    20% {
        transform: rotate(-8deg);
    }

    30% {
        transform: rotate(14deg);
    }

    40% {
        transform: rotate(-4deg);
    }

    50% {
        transform: rotate(10deg);
    }

    60% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.blinking-cursor {
    font-size: 2rem;
    color: #4062BB;
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
}

@keyframes blink {

    from,
    to {
        color: transparent;
    }

    50% {
        color: #4062BB;
    }
}

@-moz-keyframes blink {

    from,
    to {
        color: transparent;
    }

    50% {
        color: #4062BB;
    }
}

@-webkit-keyframes blink {

    from,
    to {
        color: transparent;
    }

    50% {
        color: #4062BB;
    }
}

@-ms-keyframes blink {

    from,
    to {
        color: transparent;
    }

    50% {
        color: #4062BB;
    }
}

@-o-keyframes blink {

    from,
    to {
        color: transparent;
    }

    50% {
        color: #4062BB;
    }
}
</style>