<template>
    <section class="bg-primary" id="services">
        <div class="relative w-full">
            <svg class="h-16 sm:h-32 w-full bg-white fill-primary" preserveAspectRatio="none" viewBox="0 0 100 100">
                <polygon points="0,0 0,100 100,100"></polygon>
            </svg>
        </div>
        <div class="container mx-auto px-4 py-20">
            <div class="flex mb-8 justify-center sm:justify-normal">
                <p class="text-sm font-semibold rounded-r-2xl rounded-tl-2xl bg-white py-2 px-4 text-primary">Services
                </p>
            </div>
            <h1 class="text-3xl md:text-4xl xl:text-5xl font-bold mb-10 text-center text-white sm:text-left">Wij creëren digitale
                producten<br> die helpen
                je vooruit</h1>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-8 xl:gap-16">
                <div class="grid grid-cols-12 gap-y-4 xl:gap-y-10">
                    <div
                        class="col-span-12 xl:col-span-11 xl:col-start-2 rounded-2xl bg-white flex flex-row gap-4 px-10 py-6 shadow-lg">
                        <div>
                            <i class="fa-solid fa-globe bg-primary text-white p-4 rounded-xl"></i>
                        </div>
                        <div>
                            <h2 class="text-xl xl:text-2xl font-semibold">Maatwerk websites</h2>
                            <p class="text-sm xl:text-base">Wij maken op maat gemaakte websites die perfect zijn afgestemd op uw bedrijfsbehoefts. 
                                Ons doel is om uw bedrijf te laten opvallen in de digitale wereld.</p>
                        </div>
                    </div>
                    <div
                        class="col-span-12 xl:col-span-11 xl:col-start-1 rounded-2xl bg-white flex flex-row gap-4 px-10 py-6 shadow-lg">
                        <div>
                            <i class="fa-solid fa-server bg-primary text-white p-4 rounded-xl"></i>
                        </div>
                        <div>
                            <h2 class="text-xl xl:text-2xl font-semibold">Onderhoud & Hosting</h2>
                            <p class="text-sm xl:text-base">Laat het technische werk aan ons over. Met onze betrouwbare
                                onderhouds- en
                                hostingdiensten blijft uw website soepel draaien, zodat u kunt concentreren op
                                het laten groeien van uw bedrijf.</p>
                        </div>
                    </div>
                    <div
                        class="col-span-12 xl:col-span-11 xl:col-start-2 rounded-2xl bg-white flex flex-row gap-4 px-10 py-6 shadow-lg">
                        <div>
                            <i class="fa-solid fa-magnifying-glass-chart bg-primary text-white p-4 rounded-xl"></i>
                        </div>
                        <div>
                            <h2 class="text-xl xl:text-2xl font-semibold">SEO</h2>
                            <p class="text-sm xl:text-base">Verhoog uw online zichtbaarheid en trek meer klanten aan
                                met onze effectieve
                                SEO-strategieën. Wij optimaliseren uw website om hoger te scoren in zoekresultaten,
                                waardoor je meer bereik en potentiële klanten worden bereikt.</p>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-2 grid-rows-2 gap-4">
                    <img src="img/services4.jpg" alt="servicesimage.png"
                        class="row-span-2 w-full h-full object-cover rounded-2xl shadow-md" />
                    <img src="img/services2.jpg" alt="servicesimage.png" class="w-full h-full object-cover rounded-2xl shadow-md" />
                    <img src="img/services3.jpg" alt="servicesimage.png" class="w-full h-full object-cover rounded-2xl shadow-md" />
                </div>
            </div>
        </div>
        <div class="relative w-full">
            <svg class="h-16 sm:h-32 w-full bg-primary fill-white" preserveAspectRatio="none" viewBox="0 0 100 100">
                <polygon points="0,0 0,100 100,100"></polygon>
            </svg>
        </div>
    </section>
</template>