<template>
  <section class="bg-white absolute w-full px-4" :class="{ 'shadow-scroll': isScrolled }">
    <nav class="mx-auto flex container items-center justify-between max-h-30" aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="./" class="-m-1.5 p-1.5">
          <img alt="logo.png" src="img/logo.png" class="h-16 sm:h-20" />
        </a>
      </div>
      <div class="flex lg:hidden">
        <button aria-label="bars" type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          @click="mobileMenuOpen = true">
          <i class="fa-solid fa-bars w-6 h-6"></i>
        </button>
      </div>
      <PopoverGroup class="hidden lg:flex lg:gap-x-2">
        <a v-for="section in sections" :key="section.id" :href="section.url"
          :class="{ 'active-link': section.active, 'text-gray-900': !section.active, 'text-white': section.active }"
          class="text-base font-semibold leading-6 hover:bg-primary hover:text-white py-2 px-4 rounded-full navbar-transition">{{ section.title }}</a>
      </PopoverGroup>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <router-link to="/contact" @click="resetActiveClasses"
          class="text-base leading-6 text-white bg-primary font-bold py-2 px-8 rounded-full">Let's talk</router-link>
      </div>
    </nav>
    <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
      <div class="fixed inset-0 z-50">
        <DialogPanel
          class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div class="flex items-center justify-between">
            <a href="#" class="-m-1.5 p-1.5 h-16 sm:h-20">
              <img alt="logo.png" src="img/logo.png" class="h-16 sm:h-20 sm:hidden" />
            </a>
            <button aria-label="xmark" type="button"
              class="-m-2.5 rounded-md p-2.5 inline-flex items-center justify-center text-gray-700"
              @click="mobileMenuOpen = false">
              <i class="fa-solid fa-xmark w-6 h-6"></i>
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="space-y-2 py-6">
                <a href="./#home" @click="mobileMenuOpen = false"
                  class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-card cursor-pointer"><i
                    class="fa-solid fa-house h-4 w-4 mr-2"></i>Home</a>
                <a href="./#services" @click="mobileMenuOpen = false"
                  class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-card cursor-pointer"><i
                    class="fa-solid fa-list h-4 w-4 mr-2"></i>Services</a>
                <a href="./#portfolio" @click="mobileMenuOpen = false"
                  class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-card cursor-pointer"><i
                    class="fa-solid fa-briefcase h-4 w-4 mr-2"></i>Portfolio</a>
                <a href="./#werkwijze" @click="mobileMenuOpen = false"
                  class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-card cursor-pointer"><i
                    class="fa-solid fa-clipboard-list h-4 w-4 mr-2"></i>Werkwijze</a>
                <a href="./#overons" @click="mobileMenuOpen = false"
                  class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-card cursor-pointer"><i
                    class="fa-solid fa-user-group h-4 w-4 mr-2"></i>Over ons</a>
              </div>
              <div class="py-6">
                <router-link to="/contact" @click="resetActiveClasses, mobileMenuOpen = false"
                  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white bg-primary hover:bg-secondary text-center">Let's
                  talk <i class="fa-regular fa-comments h-4 w-4 ml-2"></i></router-link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  </section>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { Dialog, DialogPanel, PopoverGroup } from "@headlessui/vue";
import eventBus from '../utils/EventBus';
import { useRouter } from 'vue-router';

export default {
  components: {
    Dialog,
    DialogPanel,
    PopoverGroup,
  },
  setup() {
    const router = useRouter();
    const mobileMenuOpen = ref(false);
    const isScrolled = ref(false);
    const sections = ref([
      { id: 'home', title: 'Home', active: false, url: '/#home' },
      { id: 'services', title: 'Services', active: false, url: '/#services' },
      { id: 'portfolio', title: 'Portfolio', active: false, url: '/#portfolio' },
      { id: 'werkwijze', title: 'Werkwijze', active: false, url: '/#werkwijze' },
      { id: 'overons', title: 'Over ons', active: false, url: '/#overons' },
    ]);

    const handleScroll = () => {
      isScrolled.value = window.scrollY > 0;
    };

    const scrollActive = () => {
      const scrollY = window.scrollY;

      sections.value.forEach(section => {
        const current = document.getElementById(section.id);
        if (!current) return;

        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 50;

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          section.active = true;
        } else {
          section.active = false;
        }
      });
    };

    const resetActiveClasses = () => {
      sections.value.forEach(section => {
        section.active = false;
      });
    };

    const setActiveSection = (sectionId) => {
      sections.value.forEach(section => {
        section.active = section.id === sectionId;
      });
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('scroll', scrollActive);
      scrollActive();
    });

    watch(() => router.currentRoute.value, () => {
      resetActiveClasses();
      switch(router.currentRoute.value.fullPath){
        case "/contact":
          setActiveSection('contact');
        break
        case "/#home" :
        case "/" :
          setActiveSection('home');
        break
        case "/#services" :
          setActiveSection('services');
        break
        case "/#portfolio" :
          setActiveSection('portfolio');
        break
        case "/#werkwijze" :
          setActiveSection('werkwijze');
        break
        case "/#overons" :
          setActiveSection('overons');
        break
      }
    });

    eventBus.on('trigger-navbar-function', resetActiveClasses);

    return {
      mobileMenuOpen,
      isScrolled,
      sections,
    };
  },
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .shadow-scroll {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: .3s ease;
  }
}

.navbar-transition {
  transition: .3s ease;
}

.active-link {
  background-color: #4062BB;
  color: #fff;
}
</style>
