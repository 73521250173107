<script setup>
import emailjs from '@emailjs/browser';
import { RecaptchaV2 } from "vue3-recaptcha-v2";
</script>
<template>
  <section class="bg-white py-10" id="contact">
    <div class="container mx-auto rounded-2xl bg-card px-6 lg:px-16 py-20 grid lg:grid-cols-2 gap-8 sm:shadow-xl">
      <div class="grid content-center">
        <div class="flex mb-4 justify-left">
          <p class="text-sm font-semibold rounded-r-2xl rounded-tl-2xl bg-primary py-2 px-4 text-white">Contact</p>
        </div>
        <h1 class="text-4xl font-extrabold text-primary">Get in Touch</h1>
        <p class="text-lg text-gray-800 mt-3 font-semibold">Heeft u een project in gedachte en wilt u die realiseren?<br> Neem dan contact met ons op, we horen graag over uw project en helpen je verder.</p>
        <ul class="flex mt-8 space-x-4">
          <li
            class="bg-primary hover:bg-secondary h-10 w-10 rounded-full flex items-center justify-center shrink-0 cursor-pointer hover:scale-110 transition duration-300 ease-in-out">
            <a href="https://www.linkedin.com/company/embediq" aria-label="LinkedIn" target="_blank">
              <i class="fa-brands fa-linkedin-in w-18 h-18px text-white"></i>
            </a>
          </li>
          <li
            class="bg-primary hover:bg-secondary h-10 w-10 rounded-full flex items-center justify-center shrink-0 cursor-pointer hover:scale-110 transition duration-300 ease-in-out">
            <a href="mailto:info@embediq.nl" aria-label="LinkedIn" target="_blank">
              <i class="fa-regular fa-envelope w-18 h-18px text-white"></i>
            </a>
          </li>
        </ul>
      </div>
      <div class="bg-gray-200 px-4 sm:px-12 py-10 rounded-lg">
        <form ref="form" @submit.prevent="sendEmail" class="mt-4 space-y-4">
          <p class="text-md font-semibold">Ik heb interesse in...</p>
          <ul
            class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex">
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
              <div class="flex items-center ps-3">
                <input id="horizontal-list-radio-maatwerkwebsite" type="radio" value="Maatwerk website"
                  name="user_interest" v-model="interest" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300">
                <label for="horizontal-list-radio-maatwerkwebsite"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900">Maatwerk website</label>
              </div>
            </li>
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r ">
              <div class="flex items-center ps-3">
                <input id="horizontal-list-radio-hosting" type="radio" value="Hosting" name="user_interest"
                  v-model="interest" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300">
                <label for="horizontal-list-radio-hosting"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900">Hosting</label>
              </div>
            </li>
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
              <div class="flex items-center ps-3">
                <input id="horizontal-list-radio-onderhoud" type="radio" value="Onderhoud" name="user_interest"
                  v-model="interest" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300">
                <label for="horizontal-list-radio-onderhoud"
                  class="w-full py-3 ms-2 text-sm font-medium text-gray-900">Onderhoud</label>
              </div>
            </li>
          </ul>
          <input type='text' name="user_name" id="contact-name" v-model="name" placeholder='Naam'
            class="w-full rounded-md py-3 px-4 text-sm outline-primary" required />
          <input type='email' name="user_email" id="contact-email" v-model="email" placeholder='Emailadres'
            class="w-full rounded-md py-3 px-4 text-sm outline-primary" required />
          <textarea name="user_message" id="contact-message" v-model="message"
            placeholder='Vertel iets over het project' rows="8"
            class="w-full rounded-md px-4 text-sm pt-3 outline-primary resize-none" required></textarea>
          <RecaptchaV2 size="normal" @widget-id="handleWidgetId" />
          <div id="contact-status">{{ status }}</div>
          <button aria-label="submit button" type='submit' value="Send"
            class="text-white bg-primary hover:bg-secondary font-semibold rounded-md text-sm px-4 py-3 flex items-center justify-center w-full">
            Aanvraag versturen<i class="fa-solid fa-paper-plane text-white ml-2"></i>
          </button>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      interest: '',
      name: '',
      email: '',
      message: '',
      status: ''
    };
  },
  methods: {
    sendEmail() {
      if (!this.interest || !this.name || !this.email || !this.message) {
        this.status = 'Oeps, niet alle velden zijn ingevuld ❌';
        setTimeout(() => {
          this.status = '';
        }, 5000);
      } else {
        emailjs
          .sendForm('service_4au10nd', 'template_g34br1s', this.$refs.form, {
            publicKey: 'wgcR70UU50qXOYRzH',
          })
          .then(() => {
            this.status = 'Je bericht is verstuurd ✅';
            setTimeout(() => {
              this.interest = '';
              this.status = '';
              this.name = '';
              this.email = '';
              this.message = '';
            }, 5000);
          })
          .catch(error => {
            console.log('FAILED...', error.text);
            alert('OEPS! Er is iets fouts gegaan....', error);
          });
      }
    },
  },
};
</script>