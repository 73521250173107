<template>
    <section class="bg-white" id="portfolio">
        <div class="container mx-auto px-4 pt-40 pb-20">
            <div class="flex mb-4 justify-center">
                <p class="text-sm font-semibold rounded-r-2xl rounded-tl-2xl bg-primary py-2 px-4 text-white shadow-xl">
                    Portfolio
                </p>
            </div>
            <h1 class="text-4xl lg:text-5xl text-center mb-8 font-bold">Bekijk onze recente cases</h1>
            <div class="container mx-auto relative w-full mb-20">
                <div class="w-full lg:w-11/12 lg:mr-auto lg:left-0 lg:mb-48 2xl:mb-72">
                    <img src="img/portfolio1.png" alt="portfolioimage.jpg"
                        class="w-full h-full object-cover rounded-t-3xl lg:rounded-3xl border shadow-lg" />
                </div>
                <div
                    class="lg:absolute lg:top-1/4 lg:right-0 lg:w-1/3 rounded-b-3xl lg:rounded-3xl h-full bg-card p-10 flex flex-col justify-around shadow-xl">
                    <div class="mb-4 justify-center sm:justify-normal hidden lg:flex">
                        <p class="text-sm font-semibold rounded-r-2xl rounded-tl-2xl bg-primary py-2 px-4 text-white">
                            Landing Page <i class="fa-solid fa-basket-shopping"></i></p>
                    </div>
                    <h2 class="text-2xl lg:text-4xl xl:text-6xl font-bold mb-4 text-center sm:text-left">Mekong Toko
                    </h2>
                    <p class="text-sm lg:text-lg mb-6 text-center sm:text-left">
                        Een minimalistische website die de essentie van Mekong Toko winkel vastlegt, waar bezoekers
                        worden uitgenodigd om de rijke culturele ervaring verder in de winkel.</p>
                    <div class="flex justify-center sm:justify-normal">
                        <a href="https://mekongtoko.com/" target="_blank"
                            class="inline-block px-8 py-3 bg-primary hover:bg-secondary text-white font-semibold rounded-full">Bekijk
                            website<i class="fa-solid fa-arrow-right ml-2"></i></a>
                    </div>
                </div>
            </div>
            <div class="container mx-auto relative w-full mb-20">
                <div class="w-full lg:w-11/12 lg:ml-auto lg:right-0 lg:mb-48 2xl:mb-60">
                    <img src="img/portfolio2.png" alt="1000x450.png"
                        class="w-full h-full object-cover rounded-t-3xl lg:rounded-3xl border shadow-lg" />
                </div>
                <div
                    class="lg:absolute lg:top-1/4 lg:left-0 lg:w-1/3 rounded-b-3xl lg:rounded-3xl h-full bg-card p-10 flex flex-col justify-around shadow-xl">
                    <div class="mb-4 justify-center sm:justify-normal hidden lg:flex">
                        <p class="text-sm font-semibold rounded-r-2xl rounded-tl-2xl bg-primary py-2 px-4 text-white">
                            Landing Page <i class="fa-solid fa-brush"></i></p>
                    </div>
                    <h2 class="text-2xl lg:text-4xl xl:text-6xl font-bold mb-4 text-center sm:text-left">Allers
                        Schilderwerken
                    </h2>
                    <p class="text-sm lg:text-lg mb-6 text-center sm:text-left">De website van Allers Schilderwerken
                        toont hun schilderdiensten en afgeronde projecten. Het biedt een overzicht van binnen- en
                        buitenschilderwerk en dient als een visueel aantrekkelijke bron voor potentiële klanten.</p>
                    <div class="flex justify-center sm:justify-normal">
                        <a href="https://www.allers-schilderwerken.nl/" target="_blank"
                            class="inline-block px-8 py-3 bg-primary hover:bg-secondary text-white font-semibold rounded-full">Bekijk
                            website<i class="fa-solid fa-arrow-right ml-2"></i></a>
                    </div>
                </div>
            </div>
            <div class="container mx-auto relative w-full mb-20">
                <div class="w-full lg:w-11/12 lg:mr-auto lg:left-0 lg:mb-48 2xl:mb-72">
                    <img src="img/portfolio3.png" alt="portfolioimage.jpg"
                        class="w-full h-full object-cover rounded-t-3xl lg:rounded-3xl border shadow-lg" />
                </div>
                <div
                    class="lg:absolute lg:top-1/4 lg:right-0 lg:w-1/3 rounded-b-3xl lg:rounded-3xl h-full bg-card p-10 flex flex-col justify-around shadow-xl">
                    <div class="mb-4 justify-center sm:justify-normal hidden lg:flex">
                        <p class="text-sm font-semibold rounded-r-2xl rounded-tl-2xl bg-primary py-2 px-4 text-white">
                            E-commerce <i class="fa-solid fa-basket-shopping"></i></p>
                    </div>
                    <h2 class="text-2xl lg:text-3xl xl:text-6xl font-bold mb-4 text-center sm:text-left">Snoepshop Roermond
                    </h2>
                    <p class="text-sm lg:text-lg mb-6 text-center sm:text-left">
                        De e-commerce website voor Snoepshop Roermond biedt een gebruiksvriendelijke shopervaring met een
                        breed assortiment aan snoep, eenvoudig te doorzoeken en te bestellen.</p>
                    <div class="flex justify-center sm:justify-normal">
                        <a href="https://www.snoepshop.com/" target="_blank"
                            class="inline-block px-8 py-3 bg-primary hover:bg-secondary text-white font-semibold rounded-full">Bekijk
                            website<i class="fa-solid fa-arrow-right ml-2"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style>
.fa-arrow-right {
    transition: transform 0.3s ease;
}

.inline-block:hover .fa-arrow-right {
    transform: translateX(5px);
}
</style>