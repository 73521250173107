<template>
    <section class="bg-white" id="overons">
        <div class="container mx-auto px-4 pt-40 pb-20">
            <div class="flex mb-4 justify-center">
                <p class="text-sm font-semibold rounded-r-2xl rounded-tl-2xl bg-primary py-2 px-4 text-white">Duo</p>
            </div>
            <h1 class="text-4xl md:text-5xl text-center mb-8 font-bold">Over ons</h1>
            <div class="flex flex-col text-center w-full mb-20">
                <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
                    Bij Embediq zijn we een dynamisch duo, gedreven door passie en expertise. <br>
                    Samen streven we naar uitmuntendheid in alles wat we doen.
                </p>
            </div>
            <div class="flex flex-wrap justify-center -m-4">
                <div class="p-4 lg:w-1/4 md:w-1/2">
                    <div class="h-full flex flex-col items-center text-center">
                        <img alt="team" class="flex-shrink-0 rounded-full w-2/3 bg-primary object-center mb-4"
                            src="img/overons1.png">
                        <div class="w-full">
                            <h2 class="title-font font-medium text-lg text-black">Yarno Stevens</h2>
                            <h3 class="text-primary font-semibold mb-3">Mede eigenaar</h3>
                            <span class="inline-flex">
                                <a class="text-gray-500 hover:text-primary"
                                    href="https://www.linkedin.com/in/yarnostevens" aria-label="LinkedIn"
                                    target="_blank">
                                    <i class="fa-brands fa-linkedin-in w-5 h-5"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-4 lg:w-1/4 md:w-1/2">
                    <div class="h-full flex flex-col items-center text-center">
                        <img alt="team" class="flex-shrink-0 rounded-full w-2/3 bg-primary object-center mb-4"
                            src="img/overons2.png">
                        <div class="w-full">
                            <h2 class="title-font font-medium text-lg text-black">Maikel Collin</h2>
                            <h3 class="text-primary font-semibold mb-3">Mede eigenaar</h3>
                            <span class="inline-flex gap-2">
                                <a class="text-gray-500 hover:text-primary cursor-pointer"
                                    href="https://www.linkedin.com/in/maikelcollin/" aria-label="LinkedIn"
                                    target="_blank">
                                    <i class="fa-brands fa-linkedin-in w-5 h-5"></i>
                                </a>
                                <a class="text-gray-500 hover:text-primary cursor-pointer"
                                    href="https://www.maikelcollin.nl" aria-label="Portfolio"
                                    target="_blank">
                                    <i class="fa-solid fa-globe w-5 h-5"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="bg-white">
        <div class="container mx-auto px-4 py-20">
            <div class="bg-primary text-white py-12 sm:py-16 px-16 sm:px-20 rounded-3xl shadow-xl">
                <div class="container mx-auto flex flex-col md:flex-row justify-between items-center">
                    <div class="md:w-1/2 text-center md:text-left mb-6 md:mb-0">
                        <h2 class="text-3xl md:text-5xl font-bold">Let's make something<br> great together.</h2>
                    </div>
                    <div class="md:w-1/2 flex justify-end">
                        <router-link to="/contact"
                            class="inline-block px-8 py-3 bg-white hover:bg-card text-primary font-semibold rounded-full hover:scale-110 transition duration-300 ease-in-out">Let's
                            connect <i class="fa-regular fa-comments ml-2"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>