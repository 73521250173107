<template>
    <div>
      <a v-bind:class="{ active: isActive }" class="scroll-up bg-primary hover:bg-secondary rounded-full cursor-pointer py-2 px-3 right-10 bottom-14"
          v-on:click="handleClick" href="#" title="Scroll to top" onclick="window.scrollTo(0,0);">
          <i class="fa-solid fa-arrow-up text-white w-4 h-4"></i>
      </a>
    </div>
  </template>
  
  <script>
  export default {
    mounted: function() {
      this.$nextTick(function () {
        this.scrollEventListener = window.addEventListener('scroll', this.handleScroll);
      })
    },
    beforeUnmount: function() {
      window.removeEventListener('scroll',this.scrollEventListener);
    },
    data() {
      return {
        isActive: false,
        scrollEventListener: undefined
      }
    },
    methods: {
      handleScroll: function () {
        if (window.scrollY > 100) {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
      },
      handleClick: function() {
        setTimeout(() => {
          this.isActive = false;
          window.scrollTo(0,0);
        }, 100);
      }
    }
  }
  </script>
  
  <style scoped>
  .scroll-up {
    position: fixed;
    bottom: -20%;
    transition: .4s;
  }
  .scroll-up.active {
    bottom: 3.2rem;
  }
  </style>