<template>
    <section class="bg-primary" id="werkwijze">
        <div class="relative w-full">
            <svg class="h-16 sm:h-32 w-full bg-white fill-primary" preserveAspectRatio="none" viewBox="0 0 100 100">
            <polygon points="0,100 100,100 100,0"></polygon>
        </svg>
        </div>
        <div class="container mx-auto px-4 py-20 bg-primary">
            <div class="flex mb-4 justify-center">
                <p class="text-sm font-semibold rounded-r-2xl rounded-tl-2xl bg-white py-2 px-4 text-primary">Werkwijze
                </p>
            </div>
            <div class="text-center mb-20">
                <h1 class="text-4xl md:text-5xl text-center text-white mb-8 font-bold">Hoe wij te werk gaan</h1>
            </div>
            <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
                <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
                    <div
                        class="w-20 h-20 inline-flex items-center justify-center rounded-xl bg-white text-primary mb-5 flex-shrink-0 shadow-xl">
                        <i class="fa-solid fa-comments w-10 h-10 text-primary"></i>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-white text-lg title-font font-bold mb-3">1. Kennismaking</h2>
                        <p class="leading-relaxed text-base text-white">Bij de kennismaking luisteren we aandachtig naar uw doelen en ideeën, om een helder beeld te krijgen van uw project.</p>
                    </div>
                </div>
                <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
                    <div
                        class="w-20 h-20 inline-flex items-center justify-center rounded-xl bg-white text-primary mb-5 flex-shrink-0 shadow-xl">
                        <i class="fa-solid fa-lightbulb w-10 h-10 text-primary"></i>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-white text-lg title-font font-bold mb-3">2. Ideeën</h2>
                        <p class="leading-relaxed text-base text-white">Creativiteit en innovatie staan centraal bij Embediq. Samen met u verkennen we nieuwe ideeën om uw project nog beter te maken.</p>
                    </div>
                </div>
                <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
                    <div
                        class="w-20 h-20 inline-flex items-center justify-center rounded-xl bg-white text-primary mb-5 flex-shrink-0 shadow-xl">
                        <i class="fa-solid fa-pen-ruler w-10 h-10 text-primary"></i>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-white text-lg title-font font-bold mb-3">3. UX en UI Design</h2>
                        <p class="leading-relaxed text-base text-white">Onze focus bij Embediq ligt op een gebruiksvriendelijke gebruikerservaring (UX) en aantrekkelijk ontwerp (UI), voor interfaces die aantrekken en gemakkelijk te gebruiken zijn.</p>
                    </div>
                </div>
                <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
                    <div
                        class="w-20 h-20 inline-flex items-center justify-center rounded-xl bg-white text-primary mb-5 flex-shrink-0 shadow-xl">
                        <i class="fa-solid fa-code w-10 h-10 text-primary"></i>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-white text-lg title-font font-bold mb-3">4. Ontwikkel fase</h2>
                        <p class="leading-relaxed text-base text-white">In deze fase zetten we onze afgesproken ideeën, UX en UI design om in een op maat gemaakte website. </p>
                    </div>
                </div>
                <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
                    <div
                        class="w-20 h-20 inline-flex items-center justify-center rounded-xl bg-white text-primary mb-5 flex-shrink-0 shadow-xl">
                        <i class="fa-solid fa-vial w-10 h-10 text-primary"></i>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-white text-lg title-font font-bold mb-3">5. Test fase</h2>
                        <p class="leading-relaxed text-base text-white">Kwaliteit staat bij ons voorop. We testen grondig om te zorgen dat de website snel, betrouwbaar en veilig is voordat het live gaat.</p>
                    </div>
                </div>
                <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
                    <div
                        class="w-20 h-20 inline-flex items-center justify-center rounded-xl bg-white text-primary mb-5 flex-shrink-0 shadow-xl">
                        <i class="fa-solid fa-rocket w-10 h-10 text-primary"></i>
                    </div>
                    <div class="flex-grow">
                        <h2 class="text-white text-lg title-font font-bold mb-3">6. Het resultaat</h2>
                        <p class="leading-relaxed text-base text-white">Bij Embediq streven we naar resultaten die het verschil maken. We nemen de klanten mee in ons werkproces.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative w-full">
            <svg class="h-16 sm:h-32 w-full bg-primary fill-white" preserveAspectRatio="none" viewBox="0 0 100 100">
            <polygon points="0,100 100,100 100,0"></polygon>
            </svg>
        </div>
    </section>
</template>